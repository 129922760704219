/**
 * Variables and mixins support the following device breakpoints: 
 *    
 *      S  -  320px - mobile     
 *      M  -  720px - tablet     
 *      L  -  940px - tablet     
 *      LD - 1320px - macbook air
 *      XL - 1580px - desktop    
 */

/**
 * Below are useful media query mixins that can wrap your content so the rules
 * only apply when the device width meets these sizes:    
 *
 *         Mixin      Size  Device Type      Screen Size
 *         ---------  ----  ---------------  -----------
 *         device_S    S    Small mobile     >= 320 px
 *         device_M    M    Medium tablet    >= 768 px
 *         device_L    L    Large tablet     >= 1024 px
 *         device_LD   LD   Large desktop    >= 1440 px
 *         device_XL   XL   X-Large desktop  >= 1920 px
 *
 *  Example:
 *
 *         header {
 *             height: 60px;
 *
 *             @include device_S { 
 *                 height: 11px;
 *                 nav { display: none; }
 *             }
 *
 *             @include device_XL { height: 80px; }
 *         }
 *
 */

// Media queries are ranges. Originally the max-width (upper range) was the
// next breakpoint width minus 1px. A browser on a large (retina) display 
// (scaled to increase the size of the desktop) that is sized to the maximum
// width of a media query can actually fall into the 1 pixel gap between two
// media queries and match nothing -- causing the page to render badly. 
//
// For example a 4k monitor set to 125% desktop scaling with a browser sized
// to 1919px may appear to the media queries larger than 1919px and less than
// 1920px thus matching neither the LD or XL breakpoints.
//
// Anything smaller than 1/10th of a pixel seems to round to zero causing
// two media queries to match on the both an upper and lower bound.
//
$leeway: 0.1px;

@mixin device_S  { @media /* no lower limit for small */    (max-width: #{$device-width-M  - $leeway}) {@content}}
@mixin device_M  { @media (min-width: $device-width-M)  and (max-width: #{$device-width-L  - $leeway}) {@content}}
@mixin device_L  { @media (min-width: $device-width-L)  and (max-width: #{$device-width-LD - $leeway}) {@content}}
@mixin device_LD { @media (min-width: $device-width-LD) and (max-width: #{$device-width-XL - $leeway}) {@content}}
@mixin device_XL { @media (min-width: $device-width-XL)         /* no upper limit for XL */        {@content}}

@mixin device_S_M { @media (max-width: #{$device-width-L - $leeway}) {@content}}
@mixin device_S_M_L { @media (max-width: #{$device-width-LD - $leeway}) {@content}}
@mixin device_M_L  { @media (min-width: $device-width-M) and (max-width: #{$device-width-LD - $leeway}) {@content}}
@mixin device_L_LD  { @media (min-width: $device-width-L) and (max-width: #{$device-width-XL - $leeway}) {@content}}
@mixin device_L_LD_XL { @media (min-width: $device-width-L) {@content}}
@mixin device_LD_XL { @media (min-width: $device-width-LD) {@content}}
@mixin device_M_L_LD_XL { @media (min-width: $device-width-M) {@content}}

/**
 * Combine the `_on` class with a device size class so the element is hidden
 * until the media query for that device is satisfied; after which the element
 * is shown. 
 * 
 * Example:  
 * 
 *        <nav class="sidebar _on S M L">
 *             <!--
 *                 This sidebar nav has position:absolute; clip:rect(0 0 0 0)
 *                 until one of media queries for size S, M, or L is satisfied
 *                 and the element becomes position:relative; clip:unset;  
 *             -->   
 *        </nav>
 * 
 */

._on { 
    @include clip_hide;
}

// Conditionally show based on device media queries.
@include device_S  { ._on.S  { @include clip_show; }}
@include device_M  { ._on.M  { @include clip_show; }}
@include device_L  { ._on.L  { @include clip_show; }}
@include device_LD { ._on.LD { @include clip_show; }}
@include device_XL { ._on.XL { @include clip_show; }}
