.order-summary {
    position: relative;
    margin-top: 30px;
    max-width: 450px;

    @include device_S {
        width: $container-width-S;
    }

    @include device_M_L_LD_XL {
        min-width: 400px;
    }

    .order-ct {
        width: 100%;
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;

        .order-btn-group{
            display: flex;
            justify-content: space-between;

            .back {
                width: initial;
                color:$btn-primary-color;
                border-color:$btn-primary-bgcolor;
                background-color:$btn-primary-bgcolor;
    
                .icon {
                    margin-right:4px;
        
                    svg {
                        transform:rotate(180deg);
                        width: 24px;
                        height: 8px;
                        g { stroke: #fff; }
                    }
                }
            }
        }
    }

    .receipt-head {
        @include flama_800(20px);
        margin-bottom: 12px;
    }

    .receipt {
        display: table;
        width: 100%;
        @include flama_500(18px);

        .entry {
            display: table-row;

            .label, .value {
                display: table-cell;
                padding-top: 4px;
                padding-bottom: 4px;
            }

            .label {
                width: 100%;
                padding-right: 2px;
            }

            .value {
                white-space: nowrap;
                text-align: right;

                &.neg {
                    // negative number
                    color: #aaa;
                    font-style: italic;

                    &:before {
                        content: "- ";
                    }
                }
            }

            &.full-hr > *,
            &.value-hr .value {
                &:before {
                    display: block;
                    content: " ";
                    width: 100%;
                    height: 1px;
                    min-width: 50px;
                    background-color: #ccc;
                }
            }

            &.full-hr .label {
                padding-right: 0;
            }
        }
    }

    .receipt-total {
        @include flama_800(20px);
        color: $default-link-color;
    }

    .btn-primary,
    .btn-secondary,
    .btn-disabled {
        width: 100%;
        margin-top: 15px;
        align-items: center;
    }

    .btn-warning {
        margin-top: 15px;
        align-items: center;
    }

    .btn-primary {
        border-color: $header-book-btn-bgcolor;
        background-color: $header-book-btn-bgcolor;
    }

    .loading-order {
        min-height: 250px;
        position: absolute;

        & ~ .order-ct .receipt {
            opacity: .5;
        }
    }
}
