body.accpl-modal-open {
    overflow:hidden;
}

.accpl-modal {
    position: fixed; top:0; left:0; bottom:0; right:0;

    z-index: 10000;
    display:flex;
    align-items:center;
    justify-content:center;

    .ngdialog-overlay, // ngdialog is angular legacy class
    &>.overlay {
        position: fixed; top:0; left:0; bottom:0; right:0;
        background: rgba(0,0,0,.7);
    }

    .ngdialog-content, // ngdialog is angular legacy class
    &>.content {
        animation: accpl-modal-content-fadein .5s;
        position: relative;
        
        &.closing {
            transition:opacity .5s;
            opacity:0;
        }
    }
}

@keyframes accpl-modal-content-fadein {
    0% { opacity:0; }
  100% { opacity:1; }
}