﻿html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
    font-family: inherit;
    padding: 0;
    margin: 0;
}

ul, li {
    list-style: none;
}

body {
    background-color: $page-bgcolor;
    overflow-x: hidden;
}

body, input[type=text], input[type=email], select, option, textarea, button {
    font-size: 14px;
    font-weight: 500;
    font-family: $font-family-flama;
}

// appearance of dropdowns is styled same across all
// browsers with a down caret background image
select {
    appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    background-position: right 8px center;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyODQuOTI5IDI4NC45MjkiPg0KCTxwYXRoIGQ9Ik0yODIuMDgyLDc2LjUxMWwtMTQuMjc0LTE0LjI3M2MtMS45MDItMS45MDYtNC4wOTMtMi44NTYtNi41Ny0yLjg1NmMtMi40NzEsMC00LjY2MSwwLjk1LTYuNTYzLDIuODU2TDE0Mi40NjYsMTc0LjQ0MSBMMzAuMjYyLDYyLjI0MWMtMS45MDMtMS45MDYtNC4wOTMtMi44NTYtNi41NjctMi44NTZjLTIuNDc1LDAtNC42NjUsMC45NS02LjU2NywyLjg1NkwyLjg1Niw3Ni41MTVDMC45NSw3OC40MTcsMCw4MC42MDcsMCw4My4wODIgYzAsMi40NzMsMC45NTMsNC42NjMsMi44NTYsNi41NjVsMTMzLjA0MywxMzMuMDQ2YzEuOTAyLDEuOTAzLDQuMDkzLDIuODU0LDYuNTY3LDIuODU0czQuNjYxLTAuOTUxLDYuNTYyLTIuODU0TDI4Mi4wODIsODkuNjQ3IGMxLjkwMi0xLjkwMywyLjg0Ny00LjA5MywyLjg0Ny02LjU2NUMyODQuOTI5LDgwLjYwNywyODMuOTg0LDc4LjQxNywyODIuMDgyLDc2LjUxMXoiLz4NCjwvc3ZnPg0K);
    background-size: 8px auto;
    background-repeat: no-repeat;
}

// appearance of form element borders is styled same across all browsers
select, textarea, button, input[type=text], input[type=email], {
    appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    background-color: #fff;
    border: 1px solid #eee;
}

main {
    display: block; /* fix for IE11 */
}