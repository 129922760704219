.login-form {

    display:flex;
    flex-direction: column;
    width: 455px;
    background: #fff;
    
    // configure to scroll if necessary
    overflow-y: auto;
    max-height: 100vh;
    @include narrow_scrollbar;

    // brand and close button
    .head {
        height:65px;
        position:relative;
        
        .logo {
            display:block;
            text-align:center;
            margin-top:28px;
            svg { height:30px; width:auto; }
        }
        
        .close {
            position:absolute; right:0; top:0;
            cursor:pointer;
            padding:22px;
            svg { height:12px; width:auto; fill: #737373; }
        }
    }

    // heading, form, privacy/terms, submit, register, forgot password
    .body {
        flex:1;
        padding:16px 55px;

        font-family:$content-text-font;
        color:$sub-info-text-color;
        font-weight:400;
        
        h2 {
            color:#000;
            font-family:$content-header-font;
            font-stretch:semi-condensed;
            font-size:23px;
            font-weight:700;
            letter-spacing:1px;
            text-align:center;
            margin:0 auto 14px;
        }
        
        form {
            padding-top:14px;
            display:block;

            .error {
                color:#d00;
                line-height:18px;
                font-size:14px;
                line-height:16px;
                display:none;
            }

            &.credential-error {
                padding-top:0;
                .credential.error {
                    display:block;
                    padding-bottom:14px;
                }
            }

            &.unknown-error {
                padding-top:0;
                .unknown.error {
                    display:block;
                    padding-bottom:14px;
                }
            }
        }
        
        .field {
            margin-bottom:13px;
            input {
                display:block;
                width:100%;
                border:1px solid #ebebeb;
                font-size:14px;
                padding:10px 15px;
                text-shadow:none;
                font-weight:400;

                // remove iphone effects:
                border-radius:0;
                -webkit-appearance:none;
                
                &:focus {
                    border-color:#ddd;
                    outline:none;
                }
                
                &:required {
                    background:#fff;
                }
            }
            
            /*
            .error {
                font-size:12px;
                padding:1px;
            }
            */
        }
        
        .agreement {
            font-size:10px;
            text-align:center;
            margin:18px 0 25px;
            a { color:$sub-info-text-color }
        }
        
        .btn.login {
            cursor:pointer;
            
            display: block;
            width:100%;
            text-align: center;
            text-decoration: none;
            letter-spacing: .8px;
            font-weight: 500;
            font-family:$btn-font-family;
            font-size:14px;
            padding:8px 0;
            margin-bottom:10px;

            color:#000;
            border:2px solid #000;

            &.primary { border:0; color:#fff; background:$default-link-color; }
        }
        
        .forgot-password {
            display:block;
            text-align:center;
            margin-top:10px;

            a {
                display:inline-block;
                font-size:14px;
                color:#aaa;
                text-decoration:none;
                padding:10px;
            }
        }
    }

    @include device_S { 
        width:$device-width-S;
        .body {
            &,
            .field input { font-weight:300; }

            .btn.login,
            .field input { font-size:12px; }
        }
    }
}

.loading-mask {
    position:absolute;
    width:100%;
    height:100%;
    background:rgba(255,255,255,.8);
    z-index:1;
}

.loading-glyph {
    position:absolute;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    height:100%;
    z-index:2;
}
