body.kiosk-enter-voucher .kiosk-nav .actions {
    .header-button, .cart {
        display: none;
    }
} 

.kiosk-voucher {
    
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

    .voucher-info-text {
        margin-top: 10px;
        font-size: 20px;
    }
    
    form {
        display:flex;
        align-items:center;
        justify-content:center;
        height:64px;
        margin-top:25px;

        input {
            height:100%;
            font-size:32px;
            border:1px solid #ccc;
            padding:8px;
            margin-right:12px;
            text-align:center;
            width:380px;
            outline:none;
        }
        
        button {
            height:100%;
            background:#db2b25;
            border-color:#db2b25;
        }
    }
    
    .icon svg path {
        fill:#444;
        &:nth-child(1) { fill:#bbb; }
        &:nth-child(2) { fill:#eee; }
        &:nth-child(3) { fill:#ccc; }
    }
}