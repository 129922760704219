@mixin label(){
    float: none;
    width: auto;
    text-transform: uppercase;
    font-family: $font-family-flama;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.6px;
    color: #737373;
    margin: 5px 0;
}

@mixin select(){
    appearance: none;
    width: 100%;
    margin: 0 auto;
    height: 50px;
    border-radius: 0;
    border: 1px solid #e2e2e2;
    font-family: $font-family-flama;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;

    option{
        font-family: $font-family-flama;
        font-size: 14px;
        letter-spacing: 0.4px;
        color: #444;
    }
}

.gform_wrapper{
    form{
        @include device_LD{
            display: flex;
            align-content: flex-start;
            align-items: center;
            flex-flow: row wrap;
        }
        @include device_XL{
            display: flex;
            align-content: flex-start;
            align-items: center;
            flex-flow: row wrap;
        }
    }
}

.gform_heading{
    display:none;
}

.validation_error{
    color: red;
    font-size: 10px;
    padding-left: 3%;
    padding-right: 3%;
    text-align: center;
    padding-bottom: 20px;
    @include device_LD{
        display: none;
    }
    @include device_XL{
        display: none;
    }
}

.gform_body{
    .gform_fields{
        display: flex;
        align-content: flex-start;
        align-items: center;
        flex-flow: row wrap;

        .gfield{
            height: auto;
            margin: 0;
            display: inline-block;
            flex: 0 1 100%;
            width: 94%;
            padding-left: 3%;
            padding-right: 3%;
            margin-bottom: 30px;

            .ginput_container{
                width: 100%;
                margin-left: 0;
            }

            .validation_message{
                color: red;
                font-size: 10px;
            }

            .gfield_label{
                @include label();
            }

            .ginput_counter{
                margin: 0;
                margin-top: 5px;
                font-size: 10px;
                color: #737373;
            }

            .ginput_container_select{
                position: relative;
            }

            .gfield_select{
                @include select();
            }

            input{
                width: 100%;
                margin: 0 auto;
                height: 50px;
                border: 1px solid #e2e2e2;
                font-family: $font-family-flama;
                font-size: 14px;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                letter-spacing: 0.4px;
                color: #444444;
                padding: 5px 10px;
            }

            input::placeholder{
                font-weight: 300;  font-style: italic;
            }

            textarea{
                width: 100%;
                margin: 0 auto;
                height: 50px;
                border: 1px solid #e2e2e2;
                font-family: $font-family-flama;
                font-size: 14px;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                letter-spacing: 0.4px;
                color: #444444;
                padding: 10px;
                height: 150px;
            }
        }

        .ginput_complex{

            select{
                @include select();
            }

            label{
                @include label();
                margin-bottom: 15px;
            }
        }

        .ginput_complex > span{
            display: block;
            margin-top: 15px;
        }

        .gfield:not(.gf_left_half):not(.gf_right_half){
            width: 100%;
        }

        .gf_left_half{
            flex: 0 1 50%;
            width: 50%;
            @include device_S  {
                flex: 0 1 100%;
                width: 100%;
            }
        }
        .gf_right_half{
            flex: 0 1 50%;
            width: 50%;
            @include device_S  {
                flex: 0 1 100%;
                width: 100%;
            }
        }
    }

    .gfield_visibility_hidden{
        display: none !important;
    }
}

.gform_footer{
    width: 100%;
    margin: 0;
    text-align: center;
    padding-bottom:80px;

    .gform_button{
        height: 50px;
        background: #737373;
        border:0;
        padding: 10px 20px;
        font-family: $font-family-flama;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.9px;
        text-align: center;
        color: #FFF;
    }
}
