header .brand-bar,
header nav .nav-main {
    @include device_LD { padding-left:$header-padding-left-LD; padding-right:$header-padding-right-LD; }
    @include device_XL { padding-left:$header-padding-left-XL; padding-right:$header-padding-right-XL; }
}

.scrolled, .hover-nav-menu, .hover-nav-item, .open-nav-menu {
    header .brand-bar {
        @include device_S_M_L {
            background-color:$header-active-bgcolor;
        }

        & > .iflyid .popup-tog .icon svg {
            fill: $nav-active-text-color;
            fill: $nav-active-text-color;
        }

        & > .cart {
            .icon svg {
                fill: $nav-active-text-color;
            }
            .total {
                color: $nav-cart-total-primary-color;
                background-color: $nav-cart-total-secondary-color;
            }
        }

        .nav-tog {
            .icon svg {
                fill: $nav-active-text-color;
            }
            
            // badge on S only
            .total {
                color: $nav-cart-total-primary-color;
                background-color: $nav-cart-total-secondary-color;
            }
        }

        .logo svg {
            fill:$ifly-brand-txt-color;
            path:nth-child(1), path:nth-child(2) { fill:$ifly-brand-guy-color; }
        }

        @include device_LD_XL {
            background-color:$header-active-bgcolor;
        }
    }
}

header .brand-bar {
    
    @include device_S_M_L {
        background-color:$header-inactive-bgcolor;
        @include header_fade(background-color);
    }
    @include device_S  { padding-right:$header-padding-right-S; }
    @include device_M  { padding-right:$header-padding-right-M; }
    @include device_L  { padding-right:$header-padding-right-L; }

    @include device_LD_XL {
        background-color:$header-inactive-bgcolor;
        @include header_fade(background-color);
    }

    display:flex;
    z-index: 1;
    align-items:center;
    height: $header-brand-bar-height; // M, L, LD, XL
    @include device_S  { height:$header-brand-bar-height-S; }

    .nav-tog {
        display: inline-flex;
        align-items:center;
        padding:2px 0 0 0;
        height:100%;

        .icon {
            display:inline-flex;
            svg { 
                fill:$nav-inactive-text-color;
                @include header_fade(fill);
                width:15px; height:14px;
            }
        }

        .if-shut, .if-open {
            display:flex;
        }

        // badge on S only
        .total {
            color: $nav-cart-total-secondary-color;
            background-color: $nav-cart-total-primary-color;

            top:-11px;
            left:-5px;
            z-index:1;
        }

        @include device_S { padding-left:$header-padding-left-S; width:30px + $header-padding-left-S; }
        @include device_M { padding-left:$header-padding-left-M; width:30px + $header-padding-left-M; }
        @include device_L { padding-left:$header-padding-left-L; width:30px + $header-padding-left-L; }
    }
    
    .logo {
        z-index:1;
        margin-right:auto;
        display:inline-flex;
        text-decoration:none;
        height:100%;
        align-items:center;
        position:relative;

        svg {
            @include header_fade(opacity);
            fill:$nav-inactive-text-color;
            margin-top:-2px;
            width:135px; height:29px; /* M, L, LD */
            @include device_S  { width:$ifly-logo-width-S; height:24px; left:-4px; }
            @include device_M  { width:$ifly-logo-width-M; height:32px; }
            @include device_L  { width:$ifly-logo-width-L; height:30px; }
            @include device_LD { width:$ifly-logo-width-LD; height:33px; }
            @include device_XL { width:$ifly-logo-width-XL; height:46px; }
        }
    }
    
    &> .iflyid {
        display:inline-flex;
        z-index:1;
        height:100%;
        color:#444;
        
        .popup-tog {
            display:inline-flex;
            align-items:center;
            height:100%;
            padding:0 19px;
            cursor:pointer;

            .icon {
                display:inline-flex;
                position:relative; top:1px;
                svg {
                    width:14px; height:15px;
                    fill:$nav-inactive-text-color;
                    @include header_fade(fill);
                } 
            }
        }
        .tile {
            padding:25px;
        }

        .popup-menu {
            @include popup_menu_width($iflyid-popup-menu-width, $iflyid-popup-menu-width/2, -15px);
            
        }
        
        h5 {
            color: $content-title-color;
            font-family:$content-header-font;
            font-stretch:semi-condensed;
            font-size:22px;
            font-weight:700;
            letter-spacing:1px;
            
            &.greeting { text-transform:uppercase; }
        }
        
        p {
            color: $content-text-color;
            font-family:$content-text-font;
            font-size:14px;
            font-weight:400;
            line-height:20px;
            margin:11px 0 0;
        }
        
        .btn {
            width:100%;
            margin-top:20px;
            cursor: pointer;
        }
    }

    &> .cart {
        display:inline-flex;
        z-index:1;  
        padding-left:10px;
        text-decoration:none;

        .icon {
            display:inline-flex;
            position:relative; top:5px;

            svg {
                width:19px; height:16px;
                fill:$nav-inactive-text-color;
                @include header_fade(fill);
            }
        }
        
        .total {
            margin-left: 8px;
            background-color: $nav-cart-total-primary-color;

            color: $nav-cart-total-secondary-color;
            @include header_fade(color, background-color);
        }
    }
    
    .book {
        z-index:1;
        display:inline-flex;
        text-align:center;
        text-transform:uppercase;
        text-decoration:none;
        color:$header-book-btn-color;
        font-family: $header-book-btn-font;
        background-color: $header-book-btn-bgcolor;
        margin-left:20px;
        height:36px;
        border: none;
        align-items: center;
        cursor: pointer;
        
        @include device_S  { width:115px; height:30px; }
        @include device_M  { width:161px; }
        @include device_L  { width:166px; }
        @include device_LD { width:170px; }
        @include device_XL { width:178px; height:40px;  }

        &.btn-active {
            color:$header-book-btn-color;
            background-color:$header-book-btn-bgcolor;
        }

        &.btn-inactive {
            background-color: $btn-disabled-bgcolor;
            color: $btn-disabled-color;
        }
    }
}
