/// TRACK FONT USAGE

// Create a mixin combination for each font-family and weight combination.
// Later analysis can identify exactly which font files to pre-load.

/// TODO: Flama has font-style (italic) properties which need separate fn's!!

@mixin flama_200($size, $lineHeight:'') { @include __flama(200, $size, $lineHeight); }
@mixin flama_300($size, $lineHeight:'') { @include __flama(300, $size, $lineHeight); }
@mixin flama_400($size, $lineHeight:'') { @include __flama(400, $size, $lineHeight); }
@mixin flama_500($size, $lineHeight:'') { @include __flama(500, $size, $lineHeight); }
@mixin flama_600($size, $lineHeight:'') { @include __flama(600, $size, $lineHeight); }
@mixin flama_700($size, $lineHeight:'') { @include __flama(700, $size, $lineHeight); }
@mixin flama_800($size, $lineHeight:'') { @include __flama(800, $size, $lineHeight); }
@mixin flama_900($size, $lineHeight:'') { @include __flama(900, $size, $lineHeight); }

/// TODO: UnitedSans and UnitedItalic both have font-stretch properties which need separate fn's!

@mixin united_sans_300($size, $lineHeight:'') { @include __united_sans(300, $size, $lineHeight); }
@mixin united_sans_500($size, $lineHeight:'') { @include __united_sans(500, $size, $lineHeight); }

@mixin united_italic_300($size, $lineHeight:'') { @include __united_italic(300, $size, $lineHeight); }
@mixin united_italic_500($size, $lineHeight:'') { @include __united_italic(500, $size, $lineHeight); }
@mixin united_italic_600($size, $lineHeight:'') { @include __united_italic(600, $size, $lineHeight); }
@mixin united_italic_900($size, $lineHeight:'') { @include __united_italic(900, $size, $lineHeight); }

/// Supporting Mixins for above

@mixin __flama($weight, $size, $lineHeight) {
    @include __font('Flama', $weight, $size, $lineHeight);
}

@mixin __united_sans($weight, $size, $lineHeight) {
    @include __font('UnitedSans', $weight, $size, $lineHeight);
}

@mixin __united_italic($weight, $size, $lineHeight) {
    @include __font('UnitedItalic', $weight, $size, $lineHeight);
}

@mixin __font($family, $weight, $size, $lineHeight) {
    @if ''==$lineHeight { font: $weight $size $family; }
    @else { font: $weight #{$size}/#{$lineHeight} $family; }
}