.web .legacy-view {
    display:flex;
    flex-direction:column;
    position:relative;

    iframe {
        width:100%;
        border:1px solid #aaa;
        
        @include device_S {
            height: calc(100vh - #{$header-top-bar-height-S-M + $header-brand-bar-height-S + 85px});
        }
        @include device_M {
            height: calc(100vh - #{$header-top-bar-height-S-M + $header-brand-bar-height + 85px});
        }
        @include device_L {
            height: calc(100vh - #{$header-top-bar-height-L + $header-brand-bar-height + 85px});
        }
        @include device_LD_XL {
            height: calc(100vh - #{$header-top-bar-height-LD-XL + $header-brand-bar-height + 85px});
        }
    }
}