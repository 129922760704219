.instagram {
    h4 {
        font-family: $font-family-flama;
        font-weight: bold;
        color: #444444;
        @include device_S  { font-size:14px; }
        @include device_M  { font-size:18px; margin-bottom:20px; }
        @include device_L  { font-size:18px; margin-bottom:30px; }
        @include device_LD { font-size:28px; margin-bottom:50px; }
        @include device_XL { font-size:28px; margin-bottom:80px; }
    }

    #sb_instagram.sbi_col_4.sbi_mob_col_auto #sbi_images .sbi_item {
        @include device_S  { width:100%; }
        @include device_M  { width:50%; }
    }

    #sbi_images{
        text-align: center;

        .sbi_item {

            .sbi_info {
                color: #737373;
                font-size: 14px;
                margin-top: 30px;
                @include device_S_M { margin-top: 10px; margin-bottom:20px; }

                p {
                    line-height: 20px;
                    text-align: left;
                    @include device_XL { line-height: 2.07; }
                }
            }
        }
    }
}