.flyer-search .time-search {

    margin-top:24px;
    @include device_M {
        margin-top:14px;
    }

    .timeofday ~ .timeofday {
        margin-top:16px;
    }

    .label {
        @include united_italic_500(18px);
        font-stretch:semi-condensed;
        margin-bottom:4px;
    }

    .time {
        @include device_S { min-width:85px; }
        @include device_M { min-width:99px; }
        @include device_L { min-width:130px; }
        @include device_LD_XL { min-width:145px; }
        margin:0 5px 5px 0;
    }
}