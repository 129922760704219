.kiosk-view {
    $kioskNavHeight: 88px;
    
    display:flex;
    flex-direction:column;
    
    .kiosk-nav { height:$kioskNavHeight; }
    
    .kiosk-body {
        margin-left:40px;
        height:1080px - $kioskNavHeight;
        overflow-y:auto;
    }
    
    .flyer-search {
        flex:1;
        
        .search-body {
            margin: 0 50px 20px;
        }
    }
    
    .timer {
        margin:10px 0;
        @include flama_500(54px);
    }
}