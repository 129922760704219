/**
 * An assortment of useful mixins. 
 *    
 */

// Hides an element.
@mixin clip_hide {
    position:absolute;
    clip: rect(0,0,0,0);
}

// Shows an element hidden by clip_hide.
@mixin clip_show {
    position: relative;
    clip: unset;
}

@mixin opacity_hide {
    opacity:0;
    pointer-events:none;
}

@mixin opacity_show {
    opacity:1;
    pointer-events:all;
}

@mixin narrow_scrollbar {
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: lighten($nav-text-active-color-dark, 20%);
        border-radius:2px;
        &:hover { background: $nav-text-active-color-dark; }
    }
}

@mixin noselect() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
}

@mixin popup_menu_width($width, $offsetX:0, $offsetY:0) {
    width: $width;
    margin:$offsetY 0 0 #{48px - $width + $offsetX};
}
