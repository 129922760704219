// Optional Content before iFLY Page Footer

@import "footer.gravity-form";
@import "footer.instagram";

.footer-content {
    background-color:$footer-content-bgcolor;
}

footer {
    font-family: $footer-font;
    background-color:$footer-primary-bgcolor;
    position:relative;
    
    &:before {
        content:" ";
        display:block;
        height: 6px;
        width: 100%;
        background-image: linear-gradient(to right, #142d61, #3152ce 53%, #519af1);
        // top gradient border
    }

    .max-ct,
    .base-bar {
        display:flex;
        align-items:center;
        flex-direction: column;
    }

    .logo {
        text-align:center;
        svg {
            fill:$ifly-brand-txt-color;
            path:nth-child(1), path:nth-child(2) { fill:$ifly-brand-guy-color; }
            width:169; height:37px; margin-top:74px; /* S, M, L */
        }
    }
    
    .grp h6 {
        font-family: $footer-header-font;
        display:block;
        font-weight: normal;
        font-size: 12px;
        letter-spacing:1px;
        margin-bottom:14px;
        color:$footer-header-color;
    }
    
    .opt a {
        display:block;
        text-decoration:none;
        text-transform:uppercase;
        font-weight:600;
        font-size:10px;
        padding:13px 0;
        letter-spacing:1.3px;
        color:$footer-page-link-color;
    }
    
    nav { width:100%; }
    nav ul { margin:62px 0 12px; }
    
    
    .base-bar {
        padding:36px;
        width:100%;
        text-align:center;
        font-size:8px;
        line-height:15px;
        background-color:$footer-base-bar-bgcolor;
        color:$footer-base-bar-color;
        
        a { color:$footer-base-bar-color; text-decoration:none; }
    }

    @include device_M_L_LD_XL {
        nav { 
            display:flex;
            
            div {
                width:25%;
                display:inline-block;
                text-align:center;

                ul {
                    display:inline-block;
                    text-align:left;
                }
            }
        }
    }

    .social {
        li { margin: 45px 27px 65px; }
    }

    @include device_S {
        & { text-align: center; }
        
        .social { 
            margin:30px 0;
            li { 
                margin:25px 10px;
                a { padding-top:20px; padding-bottom:20px }
            }

            .twitter   svg { width:21px; height:17px; }
            .facebook  svg { width:21px; height:17px; }
            .instagram svg { width:21px; height:17px; }
            .youtube   svg { width:21px; height:17px; }
        }
        
        .base-bar { margin-top:12px; }
        .copyright { margin-top:8px; }
    }

    @include device_M {
        // TODO: actual font measurements, these are inferred and not from any spec
        .base-bar { font-size:9px; line-height:16px; }
    }
    @include device_L  {
        // TODO: actual font measurements, these are inferred and not from any spec
        .opt a { font-size:11px; letter-spacing:1.4px; }
        .base-bar { font-size:10px; line-height:17px; }
    }
    @include device_LD {
        // TODO: actual font measurements, these are inferred and not from any spec
        .opt a { font-size:12px; letter-spacing:1.4px; } 
        .logo svg { width:211; height:46px; margin-top:73px; }
        .base-bar { font-size:11px; line-height:18px; }
    }
    @include device_XL { 
        .opt a { font-size:13px; letter-spacing:1.5px; }
        .logo svg { width:278; height:60px; margin-top:88px; }
        .base-bar { font-size:12px; line-height:normal; }
    }
}
