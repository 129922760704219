.section-heading {
    margin:5px 0 12px;

    @include device_S { @include flama_300(20px, 22px); }
    @include device_M_L_LD_XL { @include flama_300(22px, 26px); }
}

.guest.btn-primary {
    margin-bottom:5px;
    
    @include device_S { width:138px; margin-left:5px; }
    @include device_M { width:134px; }
    @include device_L { width:175px; }
    @include device_LD_XL { width:186px; }
    @include device_M_L_LD_XL { margin-right:5px; }
        
    &.selected {
        &,&:focus { background-color:$ifly-brand-txt-color; }
    }
    &:focus { background-color:rgba(0,0,0,.4); outline:none; }

    .btn-label {
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap;
    }
}

.no-session {
    text-align:center;
    margin:5px 0 12px;
    @include device_S { @include flama_300(20px, 22px); }
    @include device_M_L_LD_XL { @include flama_300(22px, 26px); }
}