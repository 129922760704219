/// APPLICATION: SHARED RULES

main {
    background-color: $content-bgcolor;

    .view-head {
        text-align:center;
        min-height:45px;
        display:flex;
        flex-direction:column;
        justify-content:center;

        @include device_S { @include flama_300(26px, 32px); }
        @include device_M_L_LD_XL { @include flama_200(42px); }
    }
}

.btn-primary,
.btn-secondary,
.btn-warning,
.btn-disabled,
.back {
    cursor: pointer;
    display:inline-flex;
    flex-direction:column;
    justify-content:center;
    text-align:center;
    white-space:nowrap;
    text-decoration:none;
    letter-spacing:0.8px;
    position:relative;

    @include device_S  { height:44px; @include flama_500(10px); padding:0 15px; letter-spacing:0.5px; }
    @include device_M  { height:44px; @include flama_500(12px); padding:0 20px; }
    @include device_L  { height:48px; @include flama_500(13px); padding:0 25px;}
    @include device_LD { height:60px; @include flama_500(14px); padding:0 30px; }
    @include device_XL { height:58px; @include flama_500(16px); padding:0 35px; }

    &.large {
        @include device_S  { font-size:$btn-large-font-size-S; padding:0 15px;  height:48px; }
        @include device_M  { font-size:$btn-large-font-size-M; padding:0 28px;  height:36px; }
        @include device_L  { font-size:$btn-large-font-size-L; padding:0 36px;  height:48px; }
        @include device_LD { font-size:$btn-large-font-size-LD; padding:0 67px; height:60px; }
        @include device_XL { font-size:$btn-large-font-size-XL; padding:0 72px; height:72px; }
    }

    .btn-icon {
        display:inline-flex;
        justify-content:center;
        align-items:center;
        height:100%;

        svg {
            position:relative; width:auto;
            height:40%; margin-right:8px;
        }
    }
}

.btn-primary {
    color:$btn-primary-color;
    border-color:$btn-primary-bgcolor;
    background-color:$btn-primary-bgcolor;
    svg { fill:$btn-primary-color; }
}

.btn-secondary {
    color:$btn-secondary-color;
    border: 2px solid $btn-secondary-color;
    background-color:$btn-secondary-bgcolor;
    svg { fill:$btn-secondary-color; }
}

.btn-warning {
    color: $btn-warning-color;
    border: 2px solid $btn-warning-color;
    background-color: $btn-secondary-bgcolor;

    svg {
        fill: $btn-warning-color;
    }
}

.btn-disabled {
    color: $btn-disabled-color;
    border: 2px solid $btn-disabled-color;
    background-color: $btn-disabled-bgcolor;
    cursor: default;
    svg {
        fill: $btn-disabled-color;
    }
}

.btn-text-only {
    border: none;
    background-color: #e0a57b;
    color: #153053;
}

.grecaptcha-badge{
    display: none;
}


.flex-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .back {
        margin-top: 15px;
        width: initial;
        color:$btn-primary-color;
        border-color:$btn-primary-bgcolor;
        background-color:$btn-primary-bgcolor;

        .icon {
            margin-right:4px;

            svg {
                transform:rotate(180deg);
                width: 24px;
                height: 8px;
                g { stroke: #fff; }
            }
        }
    }
}

.or {
    width: 100%;
    text-align: center;
    margin-top: 15px;
    font-size: 25px;
}

.flex-left-big {
    width: 60%;
    margin-right: 10px;
}

.flex-right-small {
    margin-left: auto;
    width: 40%;
}

.field-error {
    margin-top: 2px;
    min-height: 16px;
    @include flama_500(14px);
    color: red;
}
