.cart-summary {
    
    .empty-cart {
        min-height:150px;
        text-align:center;
        
        @include device_S { @include flama_300(16px, 32px); }
        @include device_M_L_LD_XL { @include flama_200(26px); }
    }
    
    .summary {
        display:flex;
        @include device_S_M { 
            flex-direction:column;
            align-items:center;
        }
        
        @include device_L_LD_XL {
            justify-content:center;
        }

        .remove-item-text {
            font-weight: bold;
            font-size: 16px;
        }
    }
    
    .cart {
        $thumb-width: $media-thumbnail-margin-right+$media-thumbnail-width;
        $thumb-width-x2: #{ 2 * $thumb-width };
        $thumb-width-x3: #{ 3 * $thumb-width };
        $thumb-width-x4: #{ 4 * $thumb-width };
        $thumb-width-x5: #{ 5 * $thumb-width };
        @include device_M  { max-width:$thumb-width-x3; }
        @include device_L  { min-width:$thumb-width-x2; max-width:$thumb-width-x3; margin-right:25px; }
        @include device_LD { min-width:$thumb-width-x2; max-width:$thumb-width-x4; margin-right:25px; }
        @include device_XL { min-width:$thumb-width-x2; max-width:$thumb-width-x5; margin-right:25px; }
    }
}