
html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
    font-family: inherit;
    padding: 0;
    margin: 0;
}

ul, li {
    list-style: none;
}

body {
//    font: $body-font;
    background-color: $page-bgcolor;
    overflow-x: hidden;
}

main {
    display:block; /* fix for IE11 */
}

/**
 * Enforce max container widths for each device breakpoints.
 */
.max-ct
{
    margin:0 auto;
    @include device_S  { width:$device-width-S; }
    @include device_M  { width:$device-width-M; }
    @include device_L  { width:$device-width-L; }
    @include device_LD { width:$device-width-LD; }
    @include device_XL { width:$device-width-XL; }
    
    overflow:hidden;
}

.social {
    li { display:inline-flex; }
    .icon { display:inline-flex; svg { fill:$social-icon-color; }}

    a { display:inline-flex; padding-left:18px; padding-right:18px; }
    .twitter   { svg { width:19px; height:19px; } position:relative; top:1px; }
    .facebook  svg { width:17px; height:17px; }
    .instagram svg { width:16px; height:17px; }
    .youtube   svg { width:21px; height:17px; }

    @include device_S {
        a { padding-left:15px; padding-right:15px; }
        .twitter   svg { width:21px; height:17px; }
        .facebook  svg { width:21px; height:17px; }
        .instagram svg { width:21px; height:17px; }
        .youtube   svg { width:21px; height:17px; }
    }
}