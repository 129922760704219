/// APPLICATION: WEB-SPECIFIC RULES

main.web {
    // Shift the main content section down below the header top bar. The amount
    // shifted by top must be offset by margin-bottom, otherwise that same size
    // of content will be clipped from the bottom.
    position:relative;
    top: $header-top-bar-height-S-M; margin-bottom:$header-top-bar-height-S-M; // top: S, M
    @include device_L  { top:$header-top-bar-height-L; margin-bottom:$header-top-bar-height-L; }
    @include device_LD_XL { top:$header-top-bar-height-LD-XL; margin-bottom:$header-top-bar-height-LD-XL; }

    @include device_S { min-height:360px; }
    @include device_M_L { min-height:700px; }
    @include device_LD_XL { min-height:715px; }
    
    /// Pages that don't require a top-most image behind the translucent header nav
    /// may specify this class so their content begins below the header navigation.
    &.clear-nav {
        @include clear_nav
    }

    /**
     * Enforce max container widths for each device breakpoints.
     */
    .max-ct {
        margin: 0 auto;
        @include device_S  { width: $device-width-S;  }
        @include device_M  { width: $device-width-M;  }
        @include device_L  { width: $device-width-L;  }
        @include device_LD { width: $device-width-LD; }
        @include device_XL { width: $device-width-XL; }

        overflow: hidden;
    }

    section .max-ct {
        padding:20px 17px;
    }

    .max-ct ~ .max-ct {
        padding-top:0;
        // adjacent max containers need not double-pad (top + bottom)
    }
}