﻿/// APPLICATION MIXINS

/**
 * ELements that don't require a top-most image behind the translucent header
 * nav may apply this mixin so content begins below the header navigation.
 */
@mixin clear_nav {
    &:before {
        content:" ";
        display:block;
        background-color:$page-bgcolor;
        height:$header-brand-bar-height;
        @include device_S { height:$header-brand-bar-height-S; }
    }
}
