.kiosk-view .checkout {
    .view-body {
        display: flex;
        justify-content: center;

        .checkout-form {
            flex: 1;
            margin-right: 40px;
        }

        .order-summary {
            margin-right: 10px; // matches KioskView.scss :: .kiosk-body
            max-width: 450px;
        }
    }
}

.left-col {
    display: inline-block;
    flex: auto;
    align-self: auto;
    align-content: center;
    vertical-align: top;
}

.right-col {
    display: inline-block;
    flex: auto;
    align-content: center;
    vertical-align: top;
}