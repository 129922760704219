
.convergence-checkout {

    .toggle-button {
        position: fixed;
        width: 200px;
        z-index: 1000;
        color: white;
        background-color: #3151ce;
        font-size: 16px;
        height: 58px;
        right: 20px;
    }

    .button-up {
        bottom: 400px;
    }

    .button-down {
        bottom: 0;
    }

    .keyboard-visible {
        display: block;
    }

    .keyboard-hidden {
        display: none;
    }
    
    .view-body {
        display: flex;
        justify-content: center;

        .checkout-form {
            flex: 1;
        }

        .order-summary {
            margin-right: 10px; // matches KioskView.scss :: .kiosk-body
            max-width: 450px;
        }
    }

    .empty-cart {
        min-height: 150px;
        text-align: center;

        @include device_S {
            @include flama_300(16px, 32px);
        }

        @include device_M_L_LD_XL {
            @include flama_200(26px);
        }
    }

    .div-clear-vouchers {
        max-width: 400px;
        padding: 10px;
        text-align: right;
    }

    .div-clear-vouchers a {
        cursor: pointer;
    }

    .order-checkout-buttons {
        margin-top: 5px;
    }
}

.convergence-voucher-form {
}

.voucher-info {
    font-size: 15px;
    text-align: center;
}

.hide-convergence-checkout {
    display: none;
}

.fieldset, .voucher-fieldset {
    margin-top: 10px;

    input, button {
        display: inline-block;
        padding: 10px 15px;
        font-size: 20px;        
        -webkit-appearance: none;
        border: 1px solid transparent;
        vertical-align: central;
    }

    input {
        width: 65%;
        border-color: #e2e2e2;
        border-radius: 5px 0 0 5px;
    }

    button {
        width: 30%;
        cursor: pointer;
        border-radius: 0 5px 5px 0;
    }

}

.voucher-fieldset {
    input {
        border-color: #e2e2e2;
    }

    button {
        background-color: #325DD4;
        color: #fff;
    }

    button:active,
    button:focus {
        background-color: #4e76e5;
    }
}

@media only screen and (max-width: 768px) {
    .convergence-voucher-form {
    }

    .convergence-checkout-form {
        .col {
            margin-left: 10px;
        }
    }
}

.convergence-checkout-body {
    max-width: 615px;
    display: flex;
    flex-flow: row wrap;

    @include device_S {
        margin-left: 20px;
    }

    @include device_L {
        margin-left: 20px;
    }
}

.convergence-checkout-kiosk-body {
    max-width: 1200px;
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 600px;

}

.convergence-checkout-form {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin-top: 30px;

    .col {
        display: flex;
        flex-direction: column;
        margin-right: 40px;
    }

    .row {
        display: flex;

        .field ~ .field {
            padding-left: 16px;
        }

        .field.cvv {
            width: 100px;

            input {
                text-align: center;
            }
        }
    }

    .field {
        margin-bottom: 16px;

        input[type="number"],
        input[type="text"],
        select {
            border: 1px solid #e2e2e2;
            width: 100%;
            color: #444;
            @include flama_500(18px);
            height: 50px;
            padding: 5px 10px;
        }

        &.autocomplete input[type="text"] {
            height: 36px;
        }

        &.phone input {
            width: 255px;
            display: block;
        }

        &.state {
            flex: 1;
        }

        &.postal-code {
            width: 200px;
        }

        &.expiration-date {
            flex: 1;
        }

        label {
            width: 100%;
            margin: 8px 0;
            @include flama_500(18px);

            b {
                @include flama_700(16px, 24px);
                color: #737373;
            }

            .expiration-month {
                width: 64px;
                text-align: center;
            }

            .expiration-year {
                width: 80px;
                text-align: center;
            }

            .date-separator {
                font-size: 150%;
                margin: 0 16px;
                display: inline-block;
                justify-content: center;
            }

            .help {
                font-weight: normal;
                margin-bottom: 4px;
            }
        }
    }
}

.kiosk-view .checkout-form {
    display: flex;
    justify-content: space-between;

    .col {
        display: flex;
        flex-direction: column;
    }

    .col:nth-child(1) {
        width: 35%;
    }

    .col:nth-child(2) {
        position: relative;
        width: 41%;
        margin: 0 51px 0 51px;

        &:before,
        &:after {
            content: " ";
            display: block;
            position: absolute;
            top: 16px;
            width: 1px;
            height: 450px;
            background-color: #eee;
        }

        &:before {
            left: -26px;
        }

        &:after {
            right: -26px;
        }
    }

    .col:nth-child(3) {
        width: 24%;
    }

    .row {
        display: flex;

        .field ~ .field {
            padding-left: 16px;
        }

        .field.cvv {
            width: 100px;

            input {
                text-align: center;
            }
        }
    }

    .field {
        margin-bottom: 16px;

        input[type="number"],
        input[type="text"],
        select {
            border: 1px solid #e2e2e2;
            width: 100%;
            color: #444;
            @include flama_500(18px);
            height: 50px;
            padding: 5px 10px;
        }

        &.autocomplete input[type="text"] {
            height: 36px;
        }

        &.phone input {
            width: 255px;
            display: block;
        }

        &.state {
            flex: 1;
        }

        &.postal-code {
            width: 200px;
        }

        &.expiration-date {
            flex: 1;
        }

        label {
            width: 100%;
            margin: 8px 0;
            @include flama_500(18px);

            b {
                @include flama_700(16px, 24px);
                color: #737373;
            }

            .expiration-month {
                width: 64px;
                text-align: center;
            }

            .expiration-year {
                width: 80px;
                text-align: center;
            }

            .date-separator {
                font-size: 150%;
                margin: 0 16px;
                display: inline-block;
                justify-content: center;
            }

            .help {
                font-weight: normal;
                margin-bottom: 4px;
            }
        }

        .field-error {
            margin-top: 4px;
            min-height: 24px;
            @include flama_500(14px);
            color: red;
        }
    }
}