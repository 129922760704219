$kiosk-media-max-width: 1280px;
$kiosk-video-max-height: 720px;

$kiosk-watermark-width:355px;
$kiosk-watermark-height:230px;

// KIOSK STYLING
.kiosk-view .media-detail {
    $kiosk-photo-max-height: 772px;

    .media-carousel {
        width:1320px;
    }

    .media-slide {
        .body {
            height:calc(100vh - #{$media-head-height + $media-actions-height + 2*$modal-outer-margin});
            max-height:$kiosk-photo-max-height + 50px;

            .video-wrap {
                display:flex;
                flex-direction:column;
                justify-content:center;
                align-items:center;
                
                .video-ct {
                    display:flex;
                    flex-direction:column;
                    justify-content:center;
                    align-items:center;
                    position:relative;
                    width:$kiosk-media-max-width;
                    height:$kiosk-video-max-height;

                    .video-duration {
                        position:absolute;
                        z-index:1;
                        top:15px;
                        right:15px;
                        color:#fff;
                        text-shadow:1px 1px 0 rgba(0,0,0,.9);
                        @include united_sans_300(34px);
                        font-stretch:semi-condensed;
                        letter-spacing:2px;
                    }

                    video {
                        width:100%;
                        position:relative;
                    }

                    $big-bg-disc-size:300px;

                    .loading-ring, .play-toggle {
                        align-items:center;
                        justify-content:center;

                        position:absolute;
                        width:100%;
                        height:100%;

                        .big-bg-disc {
                            display:inline-flex;
                            align-items:center;
                            justify-content:center;
                            background-color:rgba(0,0,0,.5);
                            border-radius:15%;
                            width:$big-bg-disc-size;
                            height:$big-bg-disc-size;
                        }
                    }

                    &.loading .loading-ring { display:flex; }
                    &.loading .play-toggle { display:none; }
                    &.play .play-toggle > * { opacity:0; }

                    .loading-ring {
                        display:none;

                        .icon:after {
                            content: " ";
                            display: block;
                            width: $big-bg-disc-size/2;
                            height: $big-bg-disc-size/2;
                            border-radius: 50%;
                            border: 5px solid #fff;
                            border-color: #fff transparent #fff transparent;
                            animation: kiosk-video-loading-ring 1.2s linear infinite;
                        }
                    }

                    .play-toggle {
                        z-index:2;
                        border:0;
                        background:transparent;
                        outline:none;

                        .icon {
                            svg {
                                transform: rotate(-90deg) // This SVG arrow points down, so rotate and
                                scale(1, 1.4); // scale it to look more like a play button.

                                position: relative;
                                width: $big-bg-disc-size/2;
                                height: auto;
                                fill: #fff;
                                left: $big-bg-disc-size/20;
                            }
                        }
                    }
                }
                
                .timeline {
                    $handle-size:50px;
                    opacity:0;
                    &.visible { opacity:1; }
                    
                    margin-top:20px;
                    height:$handle-size;
                    width:$kiosk-media-max-width;
                    position:relative;
                    overflow:hidden;
                    
                    // timeline track
                    &:before, .handle:before {
                        position:absolute;
                        display:block;
                        content:" ";
                        top:19px;
                        height:12px;
                        width:$kiosk-media-max-width;
                        background-color:rgba(0,0,0,.1)
                    }
                    
                    .track {
                        width:$kiosk-media-max-width - $handle-size;
                        position:relative;

                        .handle {
                            width:0%;
                            position:relative;
                            
                            &:after {
                                display:block;
                                content:" ";
                                position:absolute;
                                width:$handle-size;
                                height:$handle-size;
                                right:-$handle-size;
                                border-radius:50%;
                                background-color:$default-link-color;
                            }

                            &:before {
                                width:103%;
                                min-width:$handle-size;
                                background-color:$default-link-color;
                            }
                        }
                    }
                }

                .kiosk-watermark {
                    width:100%;
                    z-index:1;
                    animation: kiosk-watermark-anim-x 13s linear infinite alternate;

                    .watermark-el {
                        width:$kiosk-watermark-width;
                        height:$kiosk-watermark-height;
                        position:absolute;
                        opacity:.5;

                        animation: kiosk-watermark-anim-y 7s linear infinite alternate;

                        .logo svg {
                            position:relative; width:auto; height:75px;
                            fill:$ifly-brand-txt-color;
                            path:nth-child(1), path:nth-child(2) { fill:$ifly-brand-guy-color; }
                        }

                        .label {
                            @include united_italic_900(88px, 73px);
                            margin-top:8px;
                            color:#fff;
                            text-transform:uppercase;
                            letter-spacing:4px;
                            font-stretch: semi-condensed;
                            -webkit-text-stroke:2px $ifly-brand-txt-color;
                            -webkit-text-fill-color:#fff;
                        }
                    }
                }
            }
        }
    }
}

@keyframes kiosk-watermark-anim-x {
    100% { transform: translateX($kiosk-media-max-width - $kiosk-watermark-width); }
}

@keyframes kiosk-watermark-anim-y {
    100% { transform: translateY($kiosk-video-max-height - $kiosk-watermark-height); }
}

@keyframes kiosk-video-loading-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}