.kiosk .order-done {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    text-align:center;
    height:$kiosk-viewport-height;

    .heading {
        @include flama_400(72px);
    }
    
    .message {
        @include flama_200(42px);
    }

    .brand {
        padding:60px 0;
        svg { position:relative; width:236px; height:150px; }

        animation: 1s ease-in-out 2s 1 wiggle;
        @keyframes wiggle {
            0% {transform: rotate(0deg);}
            20% {transform: rotate(10deg);} 
            40% {transform: rotate(-10deg);}
            60% {transform: rotate(20deg);}
            80% {transform: rotate(-5deg);}
            100% {transform: rotate(0deg);}
        }        
    }

    .tunnel-message {
        @include flama_200(36px);
        margin-bottom:100px;

        // this message and its markup comes from tunnel configuration settings 
        .msg { display:none; }
        
        &.en    .msg-en,
        &.br    .msg-br,
        &.pt    .msg-br,
        &.pt-BR .msg-br { display:block; }
    }
}

.convergence-order-done {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: $kiosk-viewport-height;

    .heading {
        @include flama_400(72px);
    }

    .message {
        @include flama_200(42px);
        margin-left: 60px;
        margin-right:60px;
        margin-bottom:16px;
    }

    .qrcode-message, .qrcode-action {
        @include flama_200(24px);
        margin-bottom: 16px;
    }

    .brand {
        padding: 60px 0;

        svg {
            position: relative;
            width: 236px;
            height: 150px;
        }

        animation: 1s ease-in-out 2s 1 wiggle;

        @keyframes wiggle {
            0% {
                transform: rotate(0deg);
            }

            20% {
                transform: rotate(10deg);
            }

            40% {
                transform: rotate(-10deg);
            }

            60% {
                transform: rotate(20deg);
            }

            80% {
                transform: rotate(-5deg);
            }

            100% {
                transform: rotate(0deg);
            }
        }
    }

    .tunnel-message {
        @include flama_200(36px);
        margin-bottom: 100px;
        // this message and its markup comes from tunnel configuration settings
        .msg {
            display: none;
        }

        &.en .msg-en,
        &.br .msg-br,
        &.pt .msg-br,
        &.pt-BR .msg-br {
            display: block;
        }
    }

    .empty-cart {
        margin-top: 30px;
        min-height: 150px;
        text-align: center;

        @include device_S {
            @include flama_300(16px, 32px);
        }

        @include device_M_L_LD_XL {
            @include flama_200(26px);
        }
    }
}