.media-alert {
    display:flex;
    flex-direction:column;
    background-color:#fff;
    padding:25px;
    position:relative;
    min-width:$container-width-S;

    .close {
        position:absolute;
        top:0;
        right:0;
        border:0;
        background-color:transparent;
        padding:20px;
        outline:none;

        svg { position:relative; width:15px; height:14px; }
    }
    
    .body {
        margin:50px 0 35px;
        text-align:center;
        @include flama_400(14px, 24px);
    }
    
    .actions {
        display:flex;
        align-items:center;
        justify-content:center;
        
        &>* {
            margin-left:5px;
            &:first-child { margin-left:0; }
        }
    }
}