.flyer-search {
    
    .search-body {
        flex:1;
        display:flex;
        
        .calendar {
            display:flex;
            flex-direction:column;
            align-items:center;
            
            .btn-secondary {
                margin: 30px 0 30px 0;
                width:70%;
            }
        }
        
        @include device_S { 
            flex-direction:column;
            .refine {
                .time-search {
                    .label { margin-bottom:8px; }
                    .times { margin-left:8px; }
                }
            }
        }
        @include device_M_L_LD_XL { 
            .refine { flex:1; margin-left:24px; }
        }
    }

    .label {
        @include united_italic_500(18px);
        font-stretch:semi-condensed;
        margin:16px 0 4px;
    }

    .no-guests {
        @include device_S { @include flama_300(26px, 22px); }
        @include device_M_L_LD_XL { @include flama_200(30px); }
    }
}