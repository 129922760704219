/**
 * Inactive means the navigation is in its loaded non-interactive state.
 * Active means the navigation is in its active state. This happens when
 * the page is scrolled, or the navigation is hovered or expanded in the
 * mobile view.
 */

// Given a list of css properties, this mixin emits a transition rule
// with the fade duration applied to each of the properties. 
@mixin header_fade($props...) {
    $transitions: '';
    
    @each $prop in $props {
        @if ''!=$transitions {
            $transitions: #{$transitions}, $prop $header-fade-duration;
            
        } @else {
            $transitions: $prop $header-fade-duration;
        }
    }
    
    transition: $transitions;
}

/**
 * Produces a nicely centered badge atop a circle background. When the content
 * grows the badge expands into a pill.
 *
 * Apply background-color and color independently of this mixin. 
 */
@mixin badge($fontSize, $diameter) {
    font-family: $badge-number-circle-font;
    font-weight: 700;
    font-size: $fontSize;

    padding:0 $diameter/4;
    width:auto;
    min-width:$diameter;
    min-height: $diameter;
    line-height: $diameter;
    border-radius: $diameter/2;

    text-align: center;
    display:inline-flex;
    justify-content:center;
    flex-direction:column;
}

header {
    position:fixed;
    width:100%;
    z-index:2;

    .popup-tog, .nav-tog {
        cursor: pointer;
    }
    
    /**
     * Establishes a relationship between a toggle (css class suffix -tog) and
     * its corresponding menu (css class suffix -menu).
     *
     * <span class="popup-tog open">
     *     <i class="icon if-shut">Expand</i>
     *     <i class="icon if-open">Close</i>
     * </span> 
     * <div class="popup-menu">...</div> 
     */
    .menu, .popup-tog, .nav-tog {
        .if-open { @include clip_hide; }

        &.open {
            .if-shut { @include clip_hide; }
            .if-open { @include clip_show; }
        }
    }
    
    .popup-tog      ~ .popup-menu { display:none; }
    .popup-tog.open ~ .popup-menu { display:block; }

    .menu      > .sub-menu { display:none; }
    .menu.open > .sub-menu { display:block; }
    
    .popup-menu {
        &.open { display: block; }
        display: none;
        position: absolute;
        background: #fff;
        box-shadow:-1px 2px 5px 0 rgba(0,0,0,.13);
        z-index:2;
        user-select:none;
        
        font-family:$header-menu-font;
        color:$nav-text-active-color-dark;

        .body {
            overflow-y:auto;
            max-height:350px;
            @include narrow_scrollbar;
            
            /* example options: Tunnel Locations or Languages */
            .opt {
                display:block;

                a {
                    display:flex;
                    align-items: center;
                    padding:10px 26px;
                    text-decoration:none;
                    color:$nav-text-active-color-medium;

                    font-size:$popup-menu-title-font-size;
                    font-weight:500;
                }

                &:hover {
                    background:$popup-menu-hover-color;
                }
            }
        }
    }

    .popup-menu, .sub-menu {
        .opt {
            .check {
                margin-left: auto;
                svg { fill:$default-link-color; width:11px; height:11px;  }
            }
        }
    }

    .btn {
        font-family: $btn-font-family;
        
        display:inline-flex;
        flex-direction:column;
        justify-content:center;
        text-align:center;
        text-transform:uppercase;
        text-decoration:none;
        color:$btn-primary-color;
        background-color:$btn-primary-bgcolor;
        letter-spacing:0.8px;
        font-weight: 500;

        @include device_S  { font-size:$header-brand-bar-font-size-S; padding:0 15px; letter-spacing:0.5px;  }
        @include device_M  { font-size:$header-brand-bar-font-size-M; padding:0 20px; }
        @include device_L  { font-size:$header-brand-bar-font-size-L; padding:0 25px;}
        @include device_LD { font-size:$header-brand-bar-font-size-LD; padding:0 20px; }
        @include device_XL { font-size:$header-brand-bar-font-size-XL; padding:0 20px; }

        &.primary{
            background-color: $default-link-color;
            color: $btn-primary-color;
            height: 50px;
        }

        &.secondary{
            background-color: $btn-primary-color;
            color: $default-link-color;
            border: solid 2px $default-link-color;
            height: 50px;
        }

        &.text{
            text-align: center;
            background: none;
            font-size: 12px;
            color: $content-text-color;
            height: 30px;
        }

        &.btn-active {
            color:$btn-primary-color;
            background-color:$btn-primary-bgcolor;
        }

        &.btn-inactive {
            background-color: $btn-disabled-bgcolor;
            color: $btn-disabled-color;
        }
    }

    .main { 
        z-index:1;
        position:relative;
    }

    .flag {
        background-size:cover;
        &.en { background-image:$usa-flag-s-url; }
        &.pt-br { background-image:$brazil-flag-s-url; }
    }
    
    .rflag {
        background-size:cover;
        &.en { background-image:$usa-flag-m-url; }
        &.pt-br { background-image:$brazil-flag-m-url; }
    }
}

.s-badge {
    @include badge($s-badge-font-size, $s-badge-diameter);
}

.m-badge {
    @include badge($m-badge-font-size, $m-badge-diameter);
}

.profile-container{
    .tile {
        h5 {
            font-family: $content-title-font;
            font-size: 26px;
            font-weight: bold;
            font-style: italic;
            line-height: 1.19;
            letter-spacing: 1px;
            color: $content-title-color;
        }
        p {
            font-family: $content-text-font;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: $content-text-color;
        }
    }
}

@import "header.top-bar";
@import "header.brand-bar";
@import "header.nav";
