.scrolled, .hover-nav-menu, .hover-nav-item, .open-nav-menu {
    header nav {

        ul.nav .top-item a.name {
            color: $nav-active-text-color;
        }

        @include device_LD_XL {
            ul.nav {
                &> li {
                    &.menu.open .top-item {
                        .name:after {
                            background-color: $nav-active-text-color;
                            // underline below active menu trigger 
                        }
                    }
                }
            }
        }
    }
}

.hover-nav-menu, .open-nav-menu { 
    header nav {

        @include device_LD_XL {
            ul.nav {
                background-color:$header-active-bgcolor;
            }
        }
    }
}

header nav {
    font-family:$header-menu-font;
    
    &+.nav-mask {
        // mask only used on S M and L
        @include opacity_hide;
    }
    
    /* location styling shared in two locations (S M L) and (LD XL) */
    .nav-main .location {
        .near {
            color:$nav-text-color-light;
            font-weight:800;
            font-size:11px;
            letter-spacing:0.3px;
        }
        
        .wrap {
            .icon {
                display:inline-flex;
                position:relative;
                top:2px;
            }
            .city,
            .phone {
                font-family:$header-city-font;
                font-size:16px;
                font-weight:900;
                font-style:italic;
                line-height:31px;
                color:$location-menu-city-color;
                display:inline-block;
                text-decoration:none;
                padding-top:4px;
                padding-left:12px;
                padding-bottom:4px;
            }
            .phone {
                display:block;
                padding-left:24px;
            }
        }
    }
    
    ul.nav {
        font-size:0; /* fixes angular's text nodes */
        
        // locale menu/nearby location visible on S M
        .loc-menu {
            display:none;
        }
        
        .top-item a.name {
            font-style:italic;
            text-decoration: none;
            color:$nav-inactive-text-color;
        }
    }
    
    /**
     * On mobile/tablet devices hide the nav element until
     * the menu is opened. 
     */
    @include device_S_M_L {
        display: flex;
        flex-direction: column;
        z-index:-1;
        
        position:relative;
        left:-$nav-menu-width-S-M-L;
        width: $nav-menu-width-S-M-L;
        height:0; 
        background-color: rgba(255, 255, 255, 0.98);
        box-shadow: 3px 0 8px 0 rgba(0, 0, 0, 0.19);
        
        // Opening the nav begins an animation where the nav-mask fades in,
        // then the nav menu is set to full height and slides in from the 
        // left edge of the viewport.
        &.open {
            // Step 1 -- fade in nav mask
            &+.nav-mask {
                transition: opacity 0.15s;
                @include opacity_show;
                // opacity mask out page background
                position: absolute;
                z-index:-2;
                top: 0;
                width: 100vw;
                height: 100vh;
                background-color: rgba(0,0,0,0.5);
            }

            // Step 2 -- set the nav menu full height before sliding it into view
            transition: height 0s,
                        left 0.15s ease-out 0.15s; // give the transition above a head start
            left: 0;
        }
        
        // Closing the nav begins an animation where the menu slides left out
        // of view, is set to zero height, and the nav-mask fades out.
        &.closing {
            // Step 1 -- slide the menu out of view, then shrink to no height
            transition: left 0.15s ease-out,
                        height 0s; 
            left:-$nav-menu-width-S-M-L;
            height:0;

            // Step 2 -- fade out nav mask
            &+.nav-mask {
                transition:opacity 0.15s 0.15s; // give the transition above a head start
                opacity:0;
            }
        }
        
        .nav-main .copyright {
            z-index:-1;
            font-size:8.5px;
            text-align:center;
            color:$nav-text-active-color-medium;
            letter-spacing:0.2px;
            margin: 28px auto;
        }

        .anchor {
            z-index:1;
            height:$header-menu-anchor-height;
            background-image:linear-gradient(to right, #142d61, #3152ce 53%, #519af1);
        }
        
        ul.nav {
            flex: 1;
            overflow-y: auto; 

            h5.head {
                flex:1;
                font-size:16px;
                font-weight:500;
                font-style:italic;
                line-height: 30px;
                letter-spacing:0.8px;
                margin-right:auto;
                color:$nav-text-active-color-dark;
                padding-left:$nav-padding-left-S-M-L;
                cursor: pointer;
                padding-top:13px;
                padding-bottom:14px;
            }
            
            
            .top-item {
                display:flex;
                align-items: center;

                border-bottom:1px solid $nav-menu-active-break-color;

                .name {
                    flex:1;
                    margin-right:auto;
                    font-weight:500;
                    font-size:14px;
                    line-height:30px;
                    letter-spacing:0.7px;
                    
                    padding-top:14px;
                    padding-bottom:10px;
                    padding-left:$nav-padding-left-S-M-L;
                }
                
                .arrow {
                }
            }

            li.menu {
                &.open .sub-menu {
                    left:0;
                }
                
                &.closing .sub-menu {
                    left:-$nav-menu-width-S-M-L;
                }
            }
            
            .sub-menu {
                display:block;
                position:absolute;
                overflow-y: auto;
                border-bottom: $header-menu-anchor-height solid transparent; // don't obstruct the blue gradient anchor 
                height:100%;
                background-color:rgba(255, 255, 255, .98);
                top:0;
                z-index:1;
                border-top:1px solid $nav-menu-active-break-color;
                left:-$nav-menu-width-S-M-L;
                width: $nav-menu-width-S-M-L;

                transition: left .15s ease-out;

                .menu-head {
                    display: flex;
                    
                    .arrow {
                        padding-right:10px;
                        svg { transform:rotate(180deg); }
                    }
                    
                    h5.head {
                        padding-left:0;
                        padding-top:12px;
                        padding-bottom:16px;
                    }

                    border-bottom:1px solid $nav-menu-active-break-color;
                }
                
                .menu-item {
                    &:not(:last-child) > a {
                        margin-left:$nav-padding-left-S-M-L;
                        border-bottom:1px solid $nav-menu-active-break-color;
                    }

                    &:last-child > a {
                        padding-left:$nav-padding-left-S-M-L;
                        border-bottom:1px solid $nav-menu-active-break-color;
                    }
                }

                .menu-item > a,
                .location.other .opt > a,
                .location.all > a {
                    display:block;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    text-decoration:none;
                    color:$nav-text-active-color-dark;
                    font-size:16px;
                    line-height:31px;
                    font-weight: 500;
                }

                .location.other {
                    .opt { display: block; }
                    .opt:not(:last-child) {
                        border-bottom: 1px solid $nav-menu-active-break-color;
                    }
                }
            }

            // S M L account menu
            .menu.account {
                border-top:1px solid $nav-menu-active-break-color;
                background:#fff;
                
                .top-item {
                    height:58px;
                    padding-right:$nav-padding-right-S-M-L;
                    padding-left:$nav-padding-left-S-M-L;
                }
                
                .toggle {
                    cursor:pointer;
                    display:flex;
                    align-items: center;
                    width:100%;
                    height:100%;
                }
                
                .iflyid {
                    a, .sep {
                        font-size: 14px;
                        font-weight: 600;
                        text-decoration: none;
                        color: $nav-active-text-color;
                        display:inline-flex;
                        align-items: center;
                        height:100%;
                    }

                    .sep {
                        margin: 0 8px;
                        font-size: 13px;
                        font-weight: 500;
                    }

                    .icon svg {
                        fill:$nav-active-text-color;
                        position:relative;
                        width: 12px; height:13px;
                        margin-right:9px;
                    }

                    .greeting {
                        font-family:$content-header-font;
                        font-size:16px;
                        font-weight:900;
                        color:$content-title-color;
                        position: relative;
                        top:-1px;
                    }
                }

                .cart {
                    display:inline-flex;
                    padding-left: 10px;
                    margin-left: auto;
                    text-decoration:none;
                    height:100%;
                    align-items:center;

                    .icon {
                        svg {
                            fill:$nav-active-text-color;
                            position:relative;
                            width: 16px; height: 13px;
                            margin-right:5px;
                        }
                    }

                    .total {
                        margin-left: 4px;
                        background-color:$nav-cart-total-secondary-color;
                        color:$nav-cart-total-primary-color;
                    }
                }

                @include device_S {
                    .cart .icon .svg { top:0; }
                }
            }
        }

        .location {
            padding-left:$nav-padding-left-S-M-L;

            .near { margin-top:22px; }

            .wrap {
                padding-bottom:14px;
                .icon svg { width:12px; height:16px; }
                .city { font-size:16px; }
            }

            border-bottom:1px solid $nav-menu-active-break-color;
        }
    }
    
    @include device_S {
        &.open { height: calc(100vh - #{$header-top-bar-height-S-M + $header-brand-bar-height-S}); }
    }
    @include device_M {
        &.open { height: calc(100vh - #{$header-top-bar-height-S-M + $header-brand-bar-height}); }
    }
    @include device_L {
        &.open { height: calc(100vh - #{$header-top-bar-height-L + $header-brand-bar-height}); }
    }

    /**
     * On desktop devices hoist the top-level menu into the
     * space between the logo and the user/cart/book button.
     */
    @include device_LD_XL {
        position:absolute;
        width:100%;
        left:0;
        top:0;

        .nav-main {
            margin:0 auto;
        }
        .anchor { display:none; }

        ul.nav {
            // header_fade, but twice as fast to "sync" with brand bar transition
            transition: background-color $header-fade-duration/2;
            position:relative;
            
            // for LD XL this element not visible, but is used to indent the 
            // first navigation menu item
            .menu.account { height:0; pointer-events:none; }
            
            &> li {
                display:inline-block;

                &.menu.open {
                    .sub-menu { 
                        @include opacity_show;
                        .location { @include opacity_show; }
                    }
                    .top-item {
                        .if-shut { @include clip_hide; }
                        .if-open { @include clip_show; }
    
                        .name:after {
                            /* underline; styled like this to keep alignment of top-level items */
                            content:" ";
                            display:block;
                            width:100%;
                            height:2px;
                            background-color:$nav-inactive-text-color;
                            @include header_fade(background-color);
                            position:relative; top:4px;
                        }
                        
                        .arrow { transform:rotate(180deg); }
                    }
                }
                    
                .top-item {
                    display: inline-flex;
                    align-items: center;
                    
                    height: $header-brand-bar-height;

                    .name {
                        font-size:12px;
                        font-weight:500;
                        letter-spacing:1px;
                        text-transform:uppercase;
                        display:inline-block;
                        font-weight: 600;
                        @include header_fade(color);
                    }

                    .arrow {
                        margin-left:4px;
                        svg { width:8px; height:5px; }
                    }
                }
                
                .sub-menu {
                    display:block;
                    @include opacity_hide;
                    // header_fade, but twice as fast to "sync" with brand bar transition
                    transition: opacity $header-fade-duration/2;
                    
                    position:absolute;
                    border-top:1px solid $nav-menu-active-break-color;
                    padding-top:20px;
                    padding-bottom:10px;
                    text-align:left;
                    top: $header-brand-bar-height;
                    
                    &:not(.locations) {
                        li.menu-item ~ li {
                            margin-left:30px;
                            &:before {
                                content:"/";
                                font-family:$header-menu-font;
                                font-size:18px;
                                font-weight:300;
                                padding-right:30px;
                                color:$nav-text-active-color-medium;
                            }
                        }
                    }
                    
                    &.locations {
                        white-space:nowrap; // prevent wrap of long location list
                        padding-top:12px;
                        padding-bottom:8px;
                    }
                    
                    .menu-item a {
                        text-decoration:none;
                        color:$nav-text-active-color-medium;
                        font-weight: 900;
                        font-size:18px;
                        line-height:31px;
                        padding:8px 0;
                        display:inline-block;
                    }
                    
                    li {
                        display:inline-block;
                    }
                    
                    .menu-item > a { 
                        padding:8px; /* don't apply to location menu items */
                    }
                    
                    .location {
                        @include opacity_hide; // toggling opacity with menu open reduces flicker
                        
                        .near { margin-top:5px; }
                        .icon svg { width:21px; height:27px; }
                        .city { font-size:30px; }
                        &.other {

                            .opt ~ .opt{
                                margin-left:30px;
                                &:before {
                                    content:"/";
                                    font-family:$header-menu-font;
                                    font-size:18px;
                                    font-weight:300;
                                    padding-right:30px;
                                    color:$nav-text-active-color-medium;
                                }
                            }
                            
                            /* stylized large angle slashes surrounding other locations */
                            position:relative; 
                            padding-right:56px;
                            margin-right:42px;
                            margin-left:88px;
                            
                            &:before { margin-left:-52px; }
                            &:after { right:0; }

                            &:before, &:after {
                                content:"";
                                display:block;
                                position:absolute;
                                top:-10px;
                                width:1px;
                                height:70px;
                                background-color:$nav-menu-active-break-color;
                                transform: rotate(18deg);
                            }
                        }
                        &.all {
                            .icon.pin {
                                margin-right:5px;
                                svg {
                                    width:24px;
                                    height:14px;
                                    color:$nav-text-active-color-dark;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include device_LD {
        .nav-main { padding-left:$nav-padding-left-LD; padding-right:$nav-padding-right-LD; }
        
        ul.nav > li {
            &.menu.open { height:165px; }

            &.menu.account {
                width:$ifly-logo-width-LD + 35px; // logo width + margin between first menu item
            }
            .top-item {
                padding-right:16px;
                .name { font-size:$header-brand-bar-font-size-LD; }
            }
            .sub-menu { 
                left:$header-padding-left-LD; 
                width:calc(100vw - #{$header-padding-left-LD + $header-padding-right-LD + $container-gutter-width});
            } 
        }
    }
    @include device_XL {
        .nav-main { padding-left:$nav-padding-left-XL; padding-right:$nav-padding-right-XL; }
        
        ul.nav > li {
            &.menu.open { height:172px; }

            &.menu.account {
                width:$ifly-logo-width-XL + 60px; // logo width + margin between first menu item 
            }
            .top-item {
                padding-right:40px;
                .name { font-size:$header-brand-bar-font-size-XL; }
            }
            .sub-menu {
                padding-top:20px;
                left:$header-padding-left-XL;
                width:calc(100vw - #{$header-padding-left-XL + $header-padding-right-XL + $container-gutter-width});
            }
        }
    }

    
    @include device_S_M {
        ul.nav .loc-menu {
            display: flex;
            flex-direction: column;

            .city {
                letter-spacing:0.2px;
            }

            .locale {

                .heading {
                    display:flex;
                    align-items:center;
                    cursor:default;

                    border-bottom:1px solid $nav-menu-active-break-color;

                    .rflag {
                        cursor:pointer;
                        width:$lang-flag-m-height;
                        height:$lang-flag-m-height;
                        border-radius:$lang-flag-m-height/2;
                    }

                    .arrow {
                        padding-left:10px;
                        padding-top:$header-menu-arrow-pad-S-M-L + 1px;
                        padding-bottom:$header-menu-arrow-pad-S-M-L + 1px;
                    }
                }
                
                .sub-menu {

                    .opt {
                        
                        &:not(:last-child) {
                            padding-left:$header-menu-arrow-pad-S-M-L;
                            padding-right:$header-menu-arrow-pad-S-M-L;
                        }
                        
                        &:last-child a {
                            padding-left:$header-menu-arrow-pad-S-M-L;
                            padding-right:$header-menu-arrow-pad-S-M-L;
                        }
                        
                        a {
                            display:flex;
                            align-items: center;
                            padding-top: 12px;
                            padding-bottom: 12px;
                            text-decoration:none;
                            border-bottom:1px solid $nav-menu-active-break-color;
                            
                            h6 {
                                color:$nav-text-active-color-dark;
                                font-size:16px;
                                line-height:31px;
                                font-weight: 500;
                            }
                        }
                        .check svg { fill:$location-menu-city-color; }
                    }
                }
            }
        }
    }
    
    @include device_S_M_L {
        .menu .arrow {
            cursor: pointer;
            flex-direction:column;
            justify-content:center;

            padding-left:$header-menu-arrow-pad-S-M-L;
            padding-top:$header-menu-arrow-pad-S-M-L;
            padding-bottom:$header-menu-arrow-pad-S-M-L - 1px;
            padding-right:$header-menu-arrow-pad-S-M-L + $m-badge-diameter/2 - $header-menu-arrow-size-S-M-L/2;

            svg { 
                width:$header-menu-arrow-size-S-M-L; 
                height:$header-menu-arrow-size-S-M-L;
            }
        }
    }
}
