.download-view {
    min-height: 50vh;

    .no-media {
        margin: 160px auto;
    }

    @include device_L_LD_XL {
        .media-grid {
            display: flex;

            .group {
                width: 50%;
                flex-grow: 1;
            }
        }
    }
}