$glyph: url("https://cdn.iflyworld.com/images/misc/spinner.gif");
$glyphWidth: 100px;
$glyphHeight: $glyphWidth;
$animDuration: 2s;

.loading-ifly {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    .wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center; /* vertical centering */
        justify-content: center; /* horizontal centering */

        .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.8;
            background: #fff;
        }

        .flyer {
            position: absolute;
            left: calc(50vw - #{$glyphWidth} / 2);
            top: calc(50vh - #{$glyphHeight} / 2);
            background: transparent 0 0 $glyph no-repeat;
            background-size: contain;
            width: $glyphWidth;
            height: $glyphHeight;
        }
    }
}