main {
    /// react-day-picker calendar widget

    .DayPicker-wrapper { margin:2px 3px 0 2px; padding-bottom:2px; }
    .DayPicker-Day--today { font-weight:unset; }
    .DayPicker-Caption > div { text-align:center; text-transform:uppercase; }
    .DayPicker-Month { margin:5px 0 0; }
    .DayPicker-Day { padding:0; }
    
    .calendar-navbar {
        position:absolute;
        display:flex;
        width:100%;
        justify-content:space-between;
        
        .navbtn {
            display:inline-flex;
            justify-content:center;
            flex-direction:column;
            text-align:center;

            border-radius:50%; background-color:#eee;
            &:hover { background-color:#e6e6e6; cursor:pointer; }

            .icon { line-height:0; }
            svg { position:relative; fill:#999; }
            &.prev svg { left:-1px; transform:rotate(180deg); }
            &.next svg { left:1px; }
            &.disabled { @include opacity_hide }
        }
    }
    
    @include device_S_M {
        .calendar-navbar { padding:0 4px;
            .navbtn { width:31px; height:31px; svg { width:10px; height:10px; }}
        }

        .DayPicker-NavButton { width:36px; height:36px; background-size:8px; }
        .DayPicker-NavButton--prev { background-position-x:13px; }
        .DayPicker-NavButton--next { background-position-x:15px; }
        .DayPicker-Caption > div { @include united_italic_600(20px, 21px); }
        .DayPicker-Day { width:41px; height:41px; font-size:16px; }
        .DayPicker-Weekday { font-size:14px; }
    }

    @include device_L_LD {
        .calendar-navbar { padding:0 7px;
            .navbtn { width:45px; height:45px; svg { width:13px; height:13px; }}
        }
        .DayPicker-NavButton { width:54px; height:54px; background-size:14px; }
        .DayPicker-NavButton--prev { background-position-x:20px; }
        .DayPicker-NavButton--next { background-position-x:26px; }
        .DayPicker-Caption > div { @include united_italic_600(30px, 36px); }
        .DayPicker-Day { width:60px; height:60px; font-size:24px; }
        .DayPicker-Weekday { font-size:21px; }
    }

    @include device_XL {
        .calendar-navbar { padding:0 10px; 
            .navbtn { width:67px; height:67px; svg { width:20px; height:20px; }}
        }
        .DayPicker-Caption > div { @include united_italic_600(40px, 56px); }
        .DayPicker-Day { width:90px; height:90px; font-size:32px; }
        .DayPicker-Weekday { font-size:28px; }
    }

    /// Kiosk is 1920x1080 which is XL
}
