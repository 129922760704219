header .top-bar {
    background: $page-bgcolor;
    min-height:$header-top-bar-height-S-M; // S, M are empty, but bar should be present
    
    @include device_L { padding-right:$header-padding-right-L; }
    @include device_LD { padding-right:$header-padding-right-LD; }
    @include device_XL { padding-right:$header-padding-right-XL; }

    &> ul { 
        display:flex;
        justify-content: flex-end;

        height: $header-top-bar-height-S-M; // height: S, M : 11 (content hidden)
        @include device_L  { height:$header-top-bar-height-L; }
        @include device_LD_XL { height:$header-top-bar-height-LD-XL; }
    }
    
    .popup-tog {
        display:inline-flex;
        align-items:center;
        height:100%;
    }

    .location {
        display:inline-block;
        
        .near {
            padding-right:8px;
            color: $header-top-bar-font-color;
        }
        
        .city,
        .phone {
            font-size:10px;
            font-family:$header-city-font;
            font-weight: 700;
            font-style:italic;
            color:$header-top-bar-font-color;
            text-decoration:none;
            @include device_XL { font-size:12px; }
        }

        .phone {
            display:inline-block;
            margin-left:.5em;
        }
        
        .popup-menu {
            @include popup_menu_width($location-popup-menu-width, 27px, 2px);

            // USA / International heading
            h4 {
                padding:18px 24px 8px;
                font-size:22px;
            }
            
            // State / country grouping
            .grp {
                h6 {
                    text-transform:uppercase;
                    font-family:$content-header-font;
                    padding:18px 24px 5px;
                    color:$popup-menu-title-color;
                    letter-spacing:1.3px;
                    font-stretch:semi-condensed;
                    font-size:$popup-menu-title-font-size;
                    font-weight:800;
                }
            }

            // extra indentation for USA / Int'l heading
            .opt a, .grp h6 {
                padding-left:44px;
            }
        }
    }
    
    .locale {
        display:inline-block;
        margin-left:32px;
        
        .popup-tog {
            .rflag {
                margin-right:4px;
                width:$lang-flag-s-height; 
                height:$lang-flag-s-height;
                border-radius:$lang-flag-s-height/2;
                @include device_XL { width:13px; height:13px; }
            }

            .arrow {
                svg { width:8px; height:4px; fill:$header-top-bar-font-color; }
            }
        }

        .popup-menu {
            @include popup_menu_width($locale-popup-menu-width, -15px, 2px);
            
            // SELECT LANGUAGE heading
            h5 {
                font-family:$content-header-font;
                padding:13px 24px 5px;
                color:$popup-menu-title-color;
                letter-spacing:1.3px;
                font-stretch:semi-condensed;
                font-size:$popup-menu-title-font-size;
                font-weight:800;
            }

            // English / Portuguese
            .opt {
                .flag {
                    display:inline-flex;
                    width:$lang-flag-s-width;
                    height:$lang-flag-s-height;
                    background-position:center;
                }
                
                span {
                    display:inline-block;
                    padding-left:18px;
                    position:relative;
                }
            }
        }
    }
}

.scrolled, .hover-nav-menu, .hover-nav-item, .open-nav-menu {
    header .arrow svg {
        fill: $nav-active-text-color;
    }
}

header .arrow {
    display: inline-flex;
    svg {
        width:8px; height:4px;
        fill:$nav-inactive-text-color;
        @include header_fade(fill);
    }
}

/* Shared TOP BAR + NAV */
header .location {
    .near {
        text-transform: uppercase;
        font-weight:500;
        font-size:8px;
        font-family:$header-menu-font;
    }
}

/* NAV */
header .locale {

    .sub-menu {
        font-family:$header-menu-font;

        .opt {
            a { 
                padding:13px 0;
                display:flex;
                text-decoration:none;
                color:$nav-text-active-color-medium;
            }

            .flag {
                display:inline-flex;
                width:$lang-flag-s-width;
                height:$lang-flag-s-height;
                background-position:center;
            }

            h6 {
                color:$nav-text-active-color-dark;
                display:inline-block;
                padding-left:16px;
            }
        }
    }
}
