.flyer-search .name-search {
    input {
        padding:10px;
        
        @include device_S {
            font-size:14px;
            width:100%;
            margin-top:20px;
            border:1px solid #ccc;
        }
        @include device_M_L_LD_XL {
            font-size:22px;
            width:80%;
        }
    }

    &.with-keyboard .guests {
        overflow-y:auto;
        height:375px;
    }

    .Keyboard {
        position:initial;
        width:initial;
        padding:50px 0 0;
        height:auto;
        border-top:0;
        background-color:transparent;

        .ifly-keyboard-theme {
            width:1065px;
            padding:10px 0;
            margin:0 auto;
        }

        .hg-row {
            justify-content: center;
            .hg-button { flex-grow:initial; }
            .hg-standardBtn { width:100px; }
            .hg-button-bksp { width:160px; }
            .hg-button-space { width:900px; }
        }
    }
}